@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body , #root{
  height: 100%;
}

.react-tel-input {
    width: 300px !important;
  }
  